import request from '@/utils/request'

//客户 列表
export function GetCustomlist(data) {
  return request({
    method: 'post',
    url: '/user/member/list',
    data
  })
}
// 客户详情 
export function CustomInfo(data) {
  return request({
    method: 'post',
    url: '/user/member/show',
    data
  })
}
// 创建客户
export function CreateCustom(data) {
  return request({
    method: 'post',
    url: '/user/member/create',
    data
  })
}
// 修改客户
export function UpdateCustom(data) {
  return request({
    method: 'post',
    url: '/user/member/update',
    data
  })
}
// 公司 选择
export function GetCompanyoptionsList(data) {
  return request({
    method: 'post',
    url: '/system/company/company_options',
    data
  })
}