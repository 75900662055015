// 数据colom
export default {
  customer: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'member_id',
        key: '_0',
        ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {//todo
        title: '手机号',
        dataIndex: 'member_mobile',
        key: '_1',
        ellipsis: true,
        customRender: (name, record) => {
          return (record.member_mobile || '--')
        },
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '姓名',
        dataIndex: 'member_realname',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '头像',
        dataIndex: 'member_avatar',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '上次登录时间',
        dataIndex: 'member_last_login_time',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {
        title: '公司名称',
        dataIndex: 'company_name',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      // {
      //   title: '客户来源',
      //   dataIndex: 'member_source_type',
      //   key: '_4',
      //   ellipsis: true,
      //   customRender: (name, record) => {
      //     return record.member_source_type == 0 ? '自主上门' : record.member_source_type == 1 ? "员工推荐" : "客户推荐"
      //   },
      //   scopedSlots: { customRender: '_4' },
      // }, 
      {
        title: '状态',
        dataIndex: 'member_status',
        key: '_6',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return record.member_status == 1 ? '正常' : '禁用'
        // },
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
}